import React, { useState, useEffect } from 'react';
import Header from '../components/Header2'
import {Row,Col,Button,Form} from 'react-bootstrap';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import FormImage from '../images/delivery1.png';
import {Fade,Zoom} from 'react-reveal';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Checkbox from '@mui/material/Checkbox';
import swal from 'sweetalert';
import { FaArrowLeftLong } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


const FinalForm = () => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); 
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeOrg, setSelectedTimeOrg] = useState(null);
  const [fragile, setFragile] = useState('');
  const [junkRemoval, setJunkRemoval] = useState('');
  const [storageServices, setStorageServices] = useState('');
  const [packagingServices, setPackagingServices] = useState('');
  const [sendMyQuote, setSendMyQuote] = useState('')
  const [predata, setPredata] = useState(null);
  const [ip, setIp] = useState('');
  const [phoneError, setPhoneError] = useState('');



  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };
    fetchIP();
  }, []);
  

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);




  useEffect(() => {
    const existingData = location.state && location.state.data;

    // Check if existingData is different from predata before updating the state
    if (existingData !== predata && predata !== null) {
      setPredata(existingData);
        setFullName(predata.fullName || '');
        setEmail(predata.email || '');
        setPhone(predata.phone || '');
        setFragile(predata.fragile || '');
        setJunkRemoval(predata.junkRemoval || '');
        setStorageServices(predata.storageServices || '');
        setPackagingServices(predata.packagingServices || '');
      } else if(predata == null) {
      setPredata(existingData);
    }
  }, [location.state, predata]);

  useEffect(() => {
    const data = location.state && location.state.data;

    console.log('Data from state:', data);
    if (data !== null) {
      setFullName(data.fullName || '');
      setEmail(data.email || '');
      setPhone(data.phone || '');
   //   setSelectedTimeOrg(data.selectedTimeOrg || null);
      setFragile(data.fragile || '');
      setJunkRemoval(data.junkRemoval || '');
      setStorageServices(data.storageServices || '');
      setPackagingServices(data.packagingServices || '');
    }
  }, [location.state]);

  useEffect(() => {
     
    if (predata && predata.fullName !== null && predata.fullName !== undefined) {
       }
  }, [predata]);
  // Include selectedCircle in the dependency array
if (!predata || !predata.from || !predata.to || !predata.selectedDate || !predata.property || !predata.propertySize) {
   navigate('/');
  // return null; 
  }
  

  const handleNextButtonClick = (event) => {
    // Prevent default form submission behavior
    event.preventDefault();
  
    // Set loading to true when the form is submitted
    setLoading(true);
  
    // Create an array of state variables for logging
    const stateArray = [
      { key: 'fullName', value: fullName },
      { key: 'email', value: email },
      { key: 'phone', value: phone },
      { key: 'fragile', value: fragile },
      { key: 'junkRemoval', value: junkRemoval },
      { key: 'storageServices', value: storageServices },
      { key: 'packagingServices', value: packagingServices },
    ];
  
    const utm_campaign = sessionStorage.getItem('utm_campaign')
     const utm_id = sessionStorage.getItem('utm_id')

    // Check if fullName, email, and phone are filled
    if (fullName && email && phone && !phoneError) {
      // Prepare form data
      const formData = {
        form_id: "12345",
        type: predata.property,
        size: predata.propertySize,
        from_loc: predata.from,
        to_loc: predata.to,
        move_date: predata.selectedFormattedDate,
        name: fullName,
        ip:ip,
        email_id: email,
        mobile: phone,
        fragile: fragile,
        avail_time: selectedTime,
        junk_removal: junkRemoval,
        pack_serv: packagingServices,
        storage_serv: storageServices,
        share_quote:sendMyQuote,
        utm_campaign : utm_campaign || 'Organic',
        utm_id: utm_id || 'Organic',
      };
  
      // Log final form data
  
      // Submit form data
      fetch('https://allukmovers.com/ukmoversbackend/api/clientform/storeall', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      })
      .then(response => response.json())
      .then(data => { 
        // Handle response
        if (data.status === 'success') {
          toast.success('Quote successfully submitted', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => {
              window.location.href = '/Thankyou';
            }
          });
        } else {
          swal('Error', 'An error occurred. Please try again.', 'error');          
        }
      })
      .catch(error => {
        console.error('Error:', error);
        swal('Error', 'An error occurred while processing your request. Please try again.', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      toast.warn('Oops! You missed entering a field', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      });
      // Set loading to false when the form is submitted
      setLoading(false);
    }
  
    // Check if from, to, and selectedDate are present
    const existingData = location.state && location.state.data;
    if (!existingData || !existingData.from || !existingData.to || !existingData.selectedDate) {
      // Navigate to a different route if from, to, or selectedDate is missing
       navigate('/');
      return;
    }
    console.log('NEXT button clicked. Navigating with data:', );
  };



  

  const handlePreButtonClick = (event) => {
    event.preventDefault();   
    if (fullName) {
    const existingData = location.state && location.state.data;
    const data = {
    from: existingData.from,
    to: existingData.to,
    selectedDate: existingData.selectedDate,
    property: existingData.property,
    propertySize: existingData.propertySize,
    selectedFormattedDate: existingData.selectedFormattedDate,
    ...(existingData || {}),
    fullName: fullName || '',
    email: email || '',
    phone: phone || '',
    fragile: fragile || '',
    junkRemoval: junkRemoval || '',
    storageServices: storageServices || '',
    packagingServices: packagingServices || '',
    sendMyQuote: sendMyQuote || '',
    selectedTime: selectedTime || '',
    selectedTimeOrg: selectedTimeOrg || '',
   };
  
  
       console.log('NEXT button clicked. Navigating with data:', );

      navigate('/Propertysize', { state: { data: data } });
    } else {
      const existingData = location.state && location.state.data;
      const data = {
        from: existingData.from,
        to: existingData.to,
        selectedDate:existingData.selectedDate,
        ...(existingData || {}), // Spread existing data
      };     
       navigate('/Propertysize', { state: { data: data } });
    }
  };


  const handleTimeChange = (newTime) => {
    const selectedTime = new Date(newTime);
    const formattedTime = selectedTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
    setSelectedTime(formattedTime);
    setSelectedTimeOrg(newTime);
    // {$L: 'en', $u: undefined, $d: Thu Feb 01 2024 01:00:00 GMT+0530 (India Standard Time), $y: 2024, $M: 1
    console.log('Selected Time:', newTime);
  };

  const handleCheckboxChange = (checkboxName, checked) => {
    switch (checkboxName) {
      case 'fragile':
        setFragile(checked ? 'yes' : '');
        break;
      case 'junkRemoval':
        setJunkRemoval(checked ? 'yes' : '');
        break;
      case 'storageServices':
        setStorageServices(checked ? 'yes' : '');
        break;
      case 'sendMyQuote':
        setSendMyQuote(checked ? 'yes' : '');
        break;
      case 'packagingServices':
        setPackagingServices(checked ? 'yes' : '');
        break;
      default:
        break;
    }
  };



  // const formatPhoneNumber = (value) => {
  //   if (value.length === 10) {
  //     return value.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
  //   } else {
  //     return value;
  //   }
  // };

  // const validatePhoneNumber = (phoneNumber) => {
  //   // Regular Expression to validate UK phone numbers
  //   const ukPhoneRegex = /^(?:\+44|0)(?:7\d{9}|2\d{9}|1\d{9}|3\d{9}|8\d{8,9}|9\d{8,9})$/;
  
  //   // Test the phone number against the regex
  //   return ukPhoneRegex.test(phoneNumber);
  // };
  
  // const formatPhoneNumber = (phoneNumber) => {
  //   // Add spaces for readability (e.g., 07911 123456 or +44 7911 123456)
  //   if (phoneNumber.startsWith('07')) {
  //     return phoneNumber.replace(/(\d{5})(\d{5})/, '$1 $2'); // Format mobile numbers
  //   } else if (phoneNumber.startsWith('+44')) {
  //     return phoneNumber.replace(/(\+44)(\d{4})(\d{6})/, '$1 $2 $3'); // Format with country code
  //   } else if (phoneNumber.startsWith('0')) {
  //     return phoneNumber.replace(/(\d{4})(\d{6})/, '$1 $2'); // Format landlines
  //   }
  //   return phoneNumber; // Return as is if it doesn't match specific formatting
  // };
  
  // Example usage inside a component
  // const handlePhoneChange = (event) => {
  //   const inputValue = event.target.value;
  
  //   // Remove all non-numeric characters and limit to 11 digits
  //   const numericValue = inputValue.replace(/\D/g, '').slice(0, 11);
  
  // setPhone(formatPhoneNumber(numericValue));
  
  //   if (validatePhoneNumber(numericValue)) {
  //     setPhoneError(''); // No errord
  //   } else {
  //     setPhoneError('Invalid phone number'); // Show error
  //   }
  // };
  


  // const validateUKPhoneNumber = (number) => {
  //   const regex = /^(\+44\s?7\d{2,3}|\(?07\d{2,3}\)?)[-\s]?\d{3}[-\s]?\d{3,4}$/;
  //   return regex.test(number);
  // };

  // const handlePhoneChange = (e) => {
  //   const input = e.target.value;
  //   setPhone(input);

  //   if (!validateUKPhoneNumber(input)) {
  //     setPhoneError('Invalid UK phone number. Please check the format!');
  //   } else {
  //     setPhoneError('');
  //   }
  // };



  const theme = createTheme({
    palette: {
      primary: {
        main: '#FC4243', 
      },
      blue: {
        main: '#CACACA',
      },
    },
  });

  return (
    <div>
       <Header />
       <ToastContainer/>
       <div className='container my-3'>
        <div className='formBoxsh' >
          <div>
            <Row>
              <Col style={{padding:'0px'}} xs={12} md={5} className='FormImages'>
                <img src={FormImage} alt='dummyImage' style={{width:'100%',height: '460px',borderRadius:'10px 0px 0px 10px'}}  />
              </Col>
              <Col xs={12} md={7} style={{padding:'0px',position:'relative'}}>
               <div style={{width:'55%',height: '10%',borderRadius:'0px 10px 10px 0px', border:'1px solid #001577', marginTop:'10px', backgroundColor:'#0072BC', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
                <Zoom right><h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Your quote is only a click away</h5></Zoom>
               </div>
               <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>
              <Fade right>
              <ValidatorForm onSubmit={handleNextButtonClick}>
                <div className='mt-5 mx-4'>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <TextValidator style={{width:'100%'}} label="Enter Full Name*" type='text' variant="outlined" value={fullName}
                          onChange={(event) => setFullName(event.target.value)}   validators={['required']}
                          errorMessages={['this field is required']}/>
                      </Form.Group>
                    </Col>  
                    <Col xs={12} md={6} className='FormFinalInpu'>
                    <Form.Group>
                        <TextValidator style={{width:'100%'}} label="Enter Email ID*" type='email' variant="outlined" value={email}
                         onChange={(event) => setEmail(event.target.value)} validators={['required', 'isEmail']}
                         errorMessages={['this field is required', 'email is not valid']} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='pt-4 FormFinalInpu2'>
                    <Col xs={12} md={6}>
                      <Form.Group className='pt-2'>
                      {/* <Form.Control
                        style={{ width: '100%', height: '56px',borderColor: '#C4C4C4' }}
                        type='text'
                        placeholder='Enter Phone number*'
                        value={phone}
                        onChange={handlePhoneChange}
                        isInvalid={phoneError !== ''}/>
                      <Form.Control.Feedback type="invalid">
                        {phoneError}
                      </Form.Control.Feedback> */}
                    <TextField
                        type="number"
                        fullWidth
                        label="Enter Phone Number*"
                        variant="outlined"
                        value={phone}
                        onChange={(e)=> setPhone(e.target.value)}
                        error={phoneError !== ''}
                        helperText={phoneError}
                      />
                    </Form.Group>
                    </Col>  
                    <Col xs={12} md={6}>
                    <Form.Group>

                    <ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['TimePicker']}>
                            <TimePicker
                               label="Available Time To Call"
                               value={selectedTimeOrg}
                               onChange={handleTimeChange}
                              viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                              }}
                              sx={{
                                width: '100%',
                                '& .MuiPickersClockNumber-selected': {
                                  backgroundColor: theme.palette.primary.main,
                                  color: 'white',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: theme.palette.blue.main,
                                  },
                                  '&:hover fieldset': {
                                    borderColor: theme.palette.blue.main,
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.blue.main,
                                  },
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                  </ThemeProvider>
                    </Form.Group>
                    </Col>
                  </Row>
                   <hr/>
                   <Row>

               {/* <Col md={2}>
               <h6 style={{color:'#FC4243', marginTop:'10px'}}>We Also Provide</h6>
              </Col> */}
              <Col md={9}>
                 <Form.Group className='d-flex'>
                        <Checkbox {...label} style={{color:'#0072BC'}} checked={sendMyQuote === 'yes'}
                     onChange={(e) => handleCheckboxChange('sendMyQuote', e.target.checked)} />
                        <Form.Label className='mt-2'>Send my quote request to our alliance partners as well</Form.Label>
                      </Form.Group>
                   </Col>
                   <h6 style={{color:'#0072BC', marginTop:'5px', marginLeft:'10px'}}
                   className='weAlsoProvide'>We Also Provide</h6>

                    <Col md={4}>
                        <Form.Group className='d-flex mt-0'>
                        <Checkbox {...label} style={{color:'#0072BC'}} checked={junkRemoval === 'yes'}
                     onChange={(e) => handleCheckboxChange('junkRemoval', e.target.checked)} />
                        <Form.Label className='mt-2'>Junk Removal</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className='d-flex mt-0'>
                        <Checkbox {...label} style={{color:'#0072BC'}} checked={storageServices === 'yes'}
                         onChange={(e) => handleCheckboxChange('storageServices', e.target.checked)}/>
                        <Form.Label className='mt-2'>Storage Serivces</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className='d-flex mt-0'>
                        <Checkbox {...label} style={{color:'#0072BC'}}  checked={packagingServices === 'yes'}
                         onChange={(e) => handleCheckboxChange('packagingServices', e.target.checked)} />
                        <Form.Label className='mt-2'>Packaging Serivces</Form.Label>
                      </Form.Group>
                    </Col>
              
                   </Row>
                   
                </div>
                   <div className='text-center mb-2'>
                   <Button
                      // onClick={handleNextButtonClick}
                      type="submit" 
                      className='button-70'
                      style={{backgroundColor:'#FC4243', borderColor:'#FC4243',width:'50%', marginTop:'7px'}}
                      // disabled={loading}
                      disabled={!fullName || !email || !phone || phoneError || loading}>
                      {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <>SUBMIT</>
                      )}
                    </Button>
                   </div> 
              </ValidatorForm>
                </Fade>
              </Col>
            </Row>
          </div>
        </div>
       </div>
    </div>
  )
}

export default FinalForm





// import React, { useState, useEffect } from 'react';
// import Header from '../components/Header2'
// import {Row,Col,Button,Form} from 'react-bootstrap';
// import {Link, useLocation, useNavigate} from 'react-router-dom'
// import FormImage from '../images/delivery1.png';
// import {Fade,Zoom} from 'react-reveal';
// import TextField from '@mui/material/TextField';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import Checkbox from '@mui/material/Checkbox';
// import swal from 'sweetalert';
// import { FaArrowLeftLong } from "react-icons/fa6";
// import { ToastContainer, toast } from 'react-toastify';
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


// const FinalForm = () => {
//   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
//   const [loading, setLoading] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate(); 
//   const [fullName, setFullName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [selectedTime, setSelectedTime] = useState(null);
//   const [selectedTimeOrg, setSelectedTimeOrg] = useState(null);
//   const [fragile, setFragile] = useState('');
//   const [junkRemoval, setJunkRemoval] = useState('');
//   const [storageServices, setStorageServices] = useState('');
//   const [packagingServices, setPackagingServices] = useState('');
//   const [sendMyQuote, setSendMyQuote] = useState('')
//   const [predata, setPredata] = useState(null);
//   const [ip, setIp] = useState('');
//   const [phoneError, setPhoneError] = useState('');



//   useEffect(() => {
//     const fetchIP = async () => {
//       try {
//         const response = await fetch('https://api.ipify.org?format=json');
//         const data = await response.json();
//         setIp(data.ip);
//       } catch (error) {
//         console.error('Error fetching IP:', error);
//       }
//     };
//     fetchIP();
//   }, []);
  

//   useEffect(() => {
//     const searchParams = new URLSearchParams(window.location.search);
//     const utmCampaign = searchParams.get('utm_campaign');
//     const utmId = searchParams.get('utm_id');

//     if (utmCampaign) {
//       sessionStorage.setItem('utm_campaign', utmCampaign);
//     }

//     if (utmId) {
//       sessionStorage.setItem('utm_id', utmId);
//     }
//   }, []);




//   useEffect(() => {
//     const existingData = location.state && location.state.data;

//     // Check if existingData is different from predata before updating the state
//     if (existingData !== predata && predata !== null) {
//       setPredata(existingData);
//         setFullName(predata.fullName || '');
//         setEmail(predata.email || '');
//         setPhone(predata.phone || '');
//         setFragile(predata.fragile || '');
//         setJunkRemoval(predata.junkRemoval || '');
//         setStorageServices(predata.storageServices || '');
//         setPackagingServices(predata.packagingServices || '');
//       } else if(predata == null) {
//       setPredata(existingData);
//     }
//   }, [location.state, predata]);

//   useEffect(() => {
//     const data = location.state && location.state.data;

//     console.log('Data from state:', data);
//     if (data !== null) {
//       setFullName(data.fullName || '');
//       setEmail(data.email || '');
//       setPhone(data.phone || '');
//    //   setSelectedTimeOrg(data.selectedTimeOrg || null);
//       setFragile(data.fragile || '');
//       setJunkRemoval(data.junkRemoval || '');
//       setStorageServices(data.storageServices || '');
//       setPackagingServices(data.packagingServices || '');
//     }
//   }, [location.state]);

//   useEffect(() => {
     
//     if (predata && predata.fullName !== null && predata.fullName !== undefined) {
//        }
//   }, [predata]);
//   // Include selectedCircle in the dependency array
// if (!predata || !predata.from || !predata.to || !predata.selectedDate || !predata.property || !predata.propertySize) {
//    navigate('/');
//   // return null; 
//   }
  

//   const handleNextButtonClick = (event) => {
//     // Prevent default form submission behavior
//     event.preventDefault();
  
//     // Set loading to true when the form is submitted
//     setLoading(true);
  
//     // Create an array of state variables for logging
//     const stateArray = [
//       { key: 'fullName', value: fullName },
//       { key: 'email', value: email },
//       { key: 'phone', value: phone },
//       { key: 'fragile', value: fragile },
//       { key: 'junkRemoval', value: junkRemoval },
//       { key: 'storageServices', value: storageServices },
//       { key: 'packagingServices', value: packagingServices },
//     ];
  
//     const utm_campaign = sessionStorage.getItem('utm_campaign')
//      const utm_id = sessionStorage.getItem('utm_id')

//     // Check if fullName, email, and phone are filled
//     if (fullName && email && phone && !phoneError) {
//       // Prepare form data
//       const formData = {
//         form_id: "12345",
//         type: predata.property,
//         size: predata.propertySize,
//         from_loc: predata.from,
//         to_loc: predata.to,
//         move_date: predata.selectedFormattedDate,
//         name: fullName,
//         ip:ip,
//         email_id: email,
//         mobile: phone,
//         fragile: fragile,
//         avail_time: selectedTime,
//         junk_removal: junkRemoval,
//         pack_serv: packagingServices,
//         storage_serv: storageServices,
//         share_quote:sendMyQuote,
//         utm_campaign : utm_campaign || 'Organic',
//         utm_id: utm_id || 'Organic',
//       };
  
//       // Log final form data
//       console.log("Final form data:", formData);
  
//       // Submit form data
//       fetch('https://allukmovers.com/ukmoversbackend/api/clientform/storeall', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData)
//       })
//       .then(response => response.json())
//       .then(data => { 
//         // Handle response
//         if (data.status === 'success') {
//           toast.success('Quote successfully submitted', {
//             position: "top-right",
//             autoClose: 1000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "dark",
//             onClose: () => {
//               window.location.href = '/Thankyou';
//             }
//           });
//         } else {
//           swal('Error', 'An error occurred. Please try again.', 'error');          
//         }
//       })
//       .catch(error => {
//         console.error('Error:', error);
//         swal('Error', 'An error occurred while processing your request. Please try again.', 'error');
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//     } else {
//       toast.warn('Oops! You missed entering a field', {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark"
//       });
//       // Set loading to false when the form is submitted
//       setLoading(false);
//     }
  
//     // Check if from, to, and selectedDate are present
//     const existingData = location.state && location.state.data;
//     if (!existingData || !existingData.from || !existingData.to || !existingData.selectedDate) {
//       // Navigate to a different route if from, to, or selectedDate is missing
//       navigate('/');
//       return;
//     }
//     console.log('NEXT button clicked. Navigating with data:', );
//   };



  

//   const handlePreButtonClick = (event) => {
//     event.preventDefault();   
//     if (fullName) {
//     const existingData = location.state && location.state.data;
//     const data = {
//     from: existingData.from,
//     to: existingData.to,
//     selectedDate: existingData.selectedDate,
//     property: existingData.property,
//     propertySize: existingData.propertySize,
//     selectedFormattedDate: existingData.selectedFormattedDate,
//     ...(existingData || {}),
//     fullName: fullName || '',
//     email: email || '',
//     phone: phone || '',
//     fragile: fragile || '',
//     junkRemoval: junkRemoval || '',
//     storageServices: storageServices || '',
//     packagingServices: packagingServices || '',
//     sendMyQuote: sendMyQuote || '',
//     selectedTime: selectedTime || '',
//     selectedTimeOrg: selectedTimeOrg || '',
//    };
  
  
//        console.log('NEXT button clicked. Navigating with data:', );

//       navigate('/Propertysize', { state: { data: data } });
//     } else {
//       const existingData = location.state && location.state.data;
//       const data = {
//         from: existingData.from,
//         to: existingData.to,
//         selectedDate:existingData.selectedDate,
//         ...(existingData || {}), // Spread existing data
//       };     
//        navigate('/Propertysize', { state: { data: data } });
//     }
//   };


//   const handleTimeChange = (newTime) => {
//     const selectedTime = new Date(newTime);
//     const formattedTime = selectedTime.toLocaleTimeString([], {
//       hour: 'numeric',
//       minute: '2-digit',
//       hour12: true
//     });
//     setSelectedTime(formattedTime);
//     setSelectedTimeOrg(newTime);
//     // {$L: 'en', $u: undefined, $d: Thu Feb 01 2024 01:00:00 GMT+0530 (India Standard Time), $y: 2024, $M: 1
//     console.log('Selected Time:', newTime);
//   };

//   const handleCheckboxChange = (checkboxName, checked) => {
//     switch (checkboxName) {
//       case 'fragile':
//         setFragile(checked ? 'yes' : '');
//         break;
//       case 'junkRemoval':
//         setJunkRemoval(checked ? 'yes' : '');
//         break;
//       case 'storageServices':
//         setStorageServices(checked ? 'yes' : '');
//         break;
//       case 'sendMyQuote':
//         setSendMyQuote(checked ? 'yes' : '');
//         break;
//       case 'packagingServices':
//         setPackagingServices(checked ? 'yes' : '');
//         break;
//       default:
//         break;
//     }
//   };



//   const formatPhoneNumber = (value) => {
//     if (value.length === 10) {
//       return value.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
//     } else {
//       return value;
//     }
//   };

//   // const validatePhoneNumber = (value) => {
//   //   const pattern = /^\+?1?[- ]?\(?([2-9]\d{2})\)?[- ]?([2-9]\d{2})[- ]?(\d{4})$/;
//   //   // const allowedAreaCodes = [
//   //   //   '403', '587', '780', '825', '236', '250', '604', '778', '204', '431', '506', '709', '867',
//   //   //   '902', '867', '226', '249', '289', '343', '416', '437', '519', '613', '647', '705', '807', '905',
//   //   //   '782', '902', '418', '438', '450', '514', '579', '581', '819', '873', '306', '639', '867'
//   //   // ];
//   //   const allowedAreaCodes = [
//   //     '0113', '0114', '0115', '0116', '0117', '0118', '0121', '0122', '01223', '01224', '01225', '01226', 
//   //     '01227', '01233', '01234', '01235', '01236', '020', '0300', '0330', '0345', '070', '071', '072', '073', 
//   //     '074', '075', '0800', '0844', '0870', '0900', '447', '01793', '01922','01202','0191', '01799','0131','0191'];   
//   //   // Check if the phone number has repeating digits
//   //   const repeatingDigitsPattern = /^(\d)\1{2}-?(\d)\2{2}-?(\d)\3{3}$/;

//   //   const matches = value.match(pattern);
//   //   if (!matches) return false;

//   //   // Extract the area code and check if it's in the allowed list
//   //   const areaCode = matches[1];
//   //   if (!allowedAreaCodes.includes(areaCode)) return false;
//   //   return !repeatingDigitsPattern.test(value);
//   // };


//   const validatePhoneNumber = (value) => {
//     // Regular expression for UK phone numbers (mobile and landline) with optional dashes and spaces
//     const pattern = /^(?:\+44|0)(?:\s?-?\(?([1-9][0-9]{1,4})\)?[\s?-]?)?([0-9]{3,4})[\s?-]?(\d{4})$/;
  
//     // List of allowed UK area codes for landlines and mobile numbers (This can be expanded if needed)
//     const allowedAreaCodes = [
//       '0113', '0114', '0115', '0116', '0117', '0118', '0121', '0122', '01223', '01224', '01225', '01226', 
//       '01227', '01233', '01234', '01235', '01236', '020', '0300', '0330', '0345', '070', '071', '072', '073', 
//       '074', '075', '076', '077', '078', '079', '0800', '0844', '0870', '0900', '447', '01793', '01922', '01202',
//       '0191', '01799', '0131', '0191', '074', '075', '076', '077', '078', '079', '020', '0300'
//     ];   
  
//     // Check if the phone number matches the pattern
//     const matches = value.replace(/[\s-]/g, '').match(/^(?:\+44|0)(\d{2,4})(\d{3,4})(\d{4})$/);
//     if (!matches) return false;
  
//     // Extract the area code from the phone number
//     const areaCode = matches[1];
    
//     // Check if the area code is in the allowed list
//     if (!allowedAreaCodes.some(code => areaCode.startsWith(code))) return false;
  
//     // Check if the phone number contains repeating digits (e.g., 111-111-111)
//     const repeatingDigitsPattern = /^(?:(\d)\1{2}){2}-?(\d)\2{2}-?(\d)\3{3}$/;
//     if (repeatingDigitsPattern.test(value)) return false;
  
//     return true;
//   };
  
  

//   const handlePhoneChange = (event) => {
//     const inputValue = event.target.value;
//     const numericValue = inputValue.replace(/\D/g, '').slice(0, 10);
//     setPhone(formatPhoneNumber(numericValue));
//     if (validatePhoneNumber(numericValue)) {
//       setPhoneError('');
//     } else {
//       setPhoneError('Invalid phone number');
//     }
//   };
//   const theme = createTheme({
//     palette: {
//       primary: {
//         main: '#FC4243', 
//       },
//       blue: {
//         main: '#CACACA',
//       },
//     },
//   });

//   return (
//     <div>
//        <Header />
//        <ToastContainer/>
//        <div className='container my-3'>
//         <div className='formBoxsh' >
//           <div>
//             <Row>
//               <Col style={{padding:'0px'}} xs={12} md={5} className='FormImages'>
//                 <img src={FormImage} alt='dummyImage' style={{width:'100%',height: '460px',borderRadius:'10px 0px 0px 10px'}}  />
//               </Col>
//               <Col xs={12} md={7} style={{padding:'0px',position:'relative'}}>
//                <div style={{width:'55%',height: '10%',borderRadius:'0px 10px 10px 0px', border:'1px solid #001577', marginTop:'10px', backgroundColor:'#0072BC', display: 'flex',justifyContent: 'center',alignItems: 'center',fontWeight: '600'}}>
//                 <Zoom right><h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Your quote is only a click away</h5></Zoom>
//                </div>
//                <button class="button-70" onClick={handlePreButtonClick} style={{position: 'absolute',right: '5%',top: '1%'}}><FaArrowLeftLong className='me-2'/>Back</button>
//               <Fade right>
//               <ValidatorForm onSubmit={handleNextButtonClick}>
//                 <div className='mt-5 mx-4'>
//                   <Row>
//                     <Col xs={12} md={6}>
//                       <Form.Group>
//                         <TextValidator style={{width:'100%'}} label="Enter Full Name*" type='text' variant="outlined" value={fullName}
//                           onChange={(event) => setFullName(event.target.value)}   validators={['required']}
//                           errorMessages={['this field is required']}/>
//                       </Form.Group>
//                     </Col>  
//                     <Col xs={12} md={6} className='FormFinalInpu'>
//                     <Form.Group>
//                         <TextValidator style={{width:'100%'}} label="Enter Email ID*" type='email' variant="outlined" value={email}
//                          onChange={(event) => setEmail(event.target.value)} validators={['required', 'isEmail']}
//                          errorMessages={['this field is required', 'email is not valid']} />
//                       </Form.Group>
//                     </Col>
//                   </Row>
//                   <Row className='pt-4 FormFinalInpu2'>
//                     <Col xs={12} md={6}>
//                       <Form.Group className='pt-2'>
//                       {/* <Form.Control
//                         style={{ width: '100%', height: '56px',borderColor: '#C4C4C4' }}
//                         type='text'
//                         placeholder='Enter Phone number*'
//                         value={phone}
//                         onChange={handlePhoneChange}
//                         isInvalid={phoneError !== ''}/>
//                       <Form.Control.Feedback type="invalid">
//                         {phoneError}
//                       </Form.Control.Feedback> */}
//                         <TextField
//                         fullWidth
//                         label="Enter Phone number*"
//                         placeholder="Enter Phone number*"
//                         variant="outlined"
//                         value={phone}
//                         onChange={handlePhoneChange}
//                         error={phoneError !== ''}
//                         helperText={phoneError}/>
//                     </Form.Group>
//                     </Col>  
//                     <Col xs={12} md={6}>
//                     <Form.Group>

//                     <ThemeProvider theme={theme}>
//                       <LocalizationProvider dateAdapter={AdapterDayjs}>
//                           <DemoContainer components={['TimePicker']}>
//                             <TimePicker
//                                label="Available Time To Call"
//                                value={selectedTimeOrg}
//                                onChange={handleTimeChange}
//                               viewRenderers={{
//                                 hours: renderTimeViewClock,
//                                 minutes: renderTimeViewClock,
//                                 seconds: renderTimeViewClock,
//                               }}
//                               sx={{
//                                 width: '100%',
//                                 '& .MuiPickersClockNumber-selected': {
//                                   backgroundColor: theme.palette.primary.main,
//                                   color: 'white',
//                                 },
//                                 '& .MuiOutlinedInput-root': {
//                                   '& fieldset': {
//                                     borderColor: theme.palette.blue.main,
//                                   },
//                                   '&:hover fieldset': {
//                                     borderColor: theme.palette.blue.main,
//                                   },
//                                   '&.Mui-focused fieldset': {
//                                     borderColor: theme.palette.blue.main,
//                                   },
//                                 },
//                               }}
//                             />
//                           </DemoContainer>
//                         </LocalizationProvider>
//                   </ThemeProvider>
//                     </Form.Group>
//                     </Col>
//                   </Row>
//                    <hr/>
//                    <Row>

//                {/* <Col md={2}>
//                <h6 style={{color:'#FC4243', marginTop:'10px'}}>We Also Provide</h6>
//               </Col> */}
//               <Col md={9}>
//                  <Form.Group className='d-flex'>
//                         <Checkbox {...label} style={{color:'#0072BC'}} checked={sendMyQuote === 'yes'}
//                      onChange={(e) => handleCheckboxChange('sendMyQuote', e.target.checked)} />
//                         <Form.Label className='mt-2'>Send my quote request to our alliance partners as well</Form.Label>
//                       </Form.Group>
//                    </Col>
//                    <h6 style={{color:'#0072BC', marginTop:'5px', marginLeft:'10px'}}>We Also Provide</h6>

//                     <Col md={4}>
//                         <Form.Group className='d-flex mt-0'>
//                         <Checkbox {...label} style={{color:'#0072BC'}} checked={junkRemoval === 'yes'}
//                      onChange={(e) => handleCheckboxChange('junkRemoval', e.target.checked)} />
//                         <Form.Label className='mt-2'>Junk Removal</Form.Label>
//                       </Form.Group>
//                     </Col>
//                     <Col md={4}>
//                         <Form.Group className='d-flex mt-0'>
//                         <Checkbox {...label} style={{color:'#0072BC'}} checked={storageServices === 'yes'}
//                          onChange={(e) => handleCheckboxChange('storageServices', e.target.checked)}/>
//                         <Form.Label className='mt-2'>Storage Serivces</Form.Label>
//                       </Form.Group>
//                     </Col>
//                     <Col md={4}>
//                         <Form.Group className='d-flex mt-0'>
//                         <Checkbox {...label} style={{color:'#0072BC'}}  checked={packagingServices === 'yes'}
//                          onChange={(e) => handleCheckboxChange('packagingServices', e.target.checked)} />
//                         <Form.Label className='mt-2'>Packaging Serivces</Form.Label>
//                       </Form.Group>
//                     </Col>
              
//                    </Row>
                   
//                 </div>
//                    <div className='text-center mt-2'>
//                    <Button
//                       // onClick={handleNextButtonClick}
//                       type="submit" 
//                       className='button-70'
//                       style={{backgroundColor:'#FC4243', borderColor:'#FC4243',width:'50%', marginTop:'7px'}}
//                       // disabled={loading}
//                       disabled={!fullName || !email || !phone || phoneError || loading}>
//                       {loading ? (
//                         <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                       ) : (
//                         <>SUBMIT</>
//                       )}
//                     </Button>
//                    </div> 
//               </ValidatorForm>
//                 </Fade>
//               </Col>
//             </Row>
//           </div>
//         </div>
//        </div>
//     </div>
//   )
// }

// export default FinalForm

















